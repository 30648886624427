.MuiDrawer-paper{
    background-color: #222629 !important;
    .MuiListItem-root{
        color:white 
    }
.MuiListItemIcon-root{
    color: white;
}
}

.sideBar-title{
    
    color:white;
    h3{
        color:white
    }
}

.css-1p823my-MuiListItem-root.Mui-selected{
    background-color: rgb(255 255 255 / 8%);
}
