.css-uhb5lp{
    width: 1000px;
    max-width: 1000px !important;
    // min-width: 1000px;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    width: 1000px;
    max-width: 1000px !important;
    // min-width: 1000px;
}
