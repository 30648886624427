.colors {
    width: 35px !important;
    height: 35px !important;
    max-height: 35px !important;
    min-height: 35px !important;
    border-radius: 5px !important;
    border: 1px solid rgba(128, 128, 128, 0.255);
    cursor: pointer;

}
.active {
    border: 3px solid rgb(144, 8, 8);
}