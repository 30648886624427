.hover-mouse:hover {
    cursor: pointer;
}

.stock:hover {
    // cursor: url(../../pen-cursor.png), auto !important;
    cursor: text;
}

.dataGrid {
    width: "100%";
    margin-bottom: "25px";
}

.MuiDataGrid-row:hover {
    cursor: pointer;
}

.colorSelect .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    height: 50px !important;
    // top: -7px !important;
}

.colorSelect .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    // top:-3px !important;
}