.product-image {
    width: 300px !important;
    img {
        max-height: 350px !important;
        width: 100%;
    }
}

.card-title{
    width:300px !important;
}


@media screen and (min-width: 900px)  {    
    .product-image{
        width:250px !important;
        height: 405px;
        img {
            // max-height: 350px !important;
            width: 100%;
        }
    }
    .card-title {
        width: 250px !important;
    }
}
@media screen and (min-width: 940px)  {    
    .product-image{
        width:270px !important;
        height: 405px;
        img {
            // max-height: 350px !important;
            width: 100%;
        }
    }
    .card-title {
        width: 270px !important;
    }
}
@media screen and (min-width: 1030px)  {    
    .product-image{
        width:300px !important;
        height: 405px;
        img {
            // max-height: 350px !important;
            width: 100%;
        }
    }
    .card-title {
        width: 300px !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1300px)  {    
    .product-image{
        width:260px !important;

        img {
            // max-height: 350px !important;
            width: 100%;
        }
    }
    .card-title {
        width: 260px !important;
    }
}
