.container-box {
    padding: 10px;
    border: 1px solid rgba(128, 128, 128, 0.524);
    border-radius: 5px;

    .delete-icon-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        .icon {
            flex-direction: flex-end;
            cursor: pointer;

            &:hover {
                color: red;
            }
        }

        .drag {
            cursor: pointer;
        }
    }

    img {
        object-fit: cover;
        width: 100%;
        // height: 200px;
    }

    @media (max-width: 600px) {
        img {
            object-fit: cover;
            width: 100%;
            height: 250px;
        }
    }
}