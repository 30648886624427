.css-1p823my-MuiListItem-root.Mui-selected{
    background-color: rgb(242 242 242 / 8%) !important;
}

.sideBar-title p {
    font-size: 14px;
    color: grey;

}

.product-detail{
    box-shadow: none;
    p{
        font-size: 1.4em;
        margin-bottom: 20px;
        color: grey
    }
    h3{
        font-size: 2em;
    }
}

.swiper-backface-hidden .swiper-slide{
    width: 100% !important;
}

.swiper-button-next, .swiper-button-prev,
.swiper-rtl .swiper-button-prev{

    color: grey;
}
.swiper-button-next:after, .swiper-button-prev:after,
.swiper-rtl .swiper-button-prev:after{
    font-size: 30px;
}
// .react-slideshow-container .default-nav{
//     background-color: transparent;
    
// }
// .react-slideshow-container .default-nav:hover{
//     background-color: transparent
// }